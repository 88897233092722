<template>
    <div />
</template>

<script>
import { deviceCheckerMixin } from 'abcreche-ui'
import { defineComponent } from 'vue'

export default defineComponent({
    mixins: [deviceCheckerMixin],

    created () {
        if (this.isMobile && localStorage.getItem('tabletPopup') === null) {
            this.showTabletPopup()
        }
    },

    methods: {
        showTabletPopup () {
            setTimeout(() => this.$dialog.show({
                title: 'Passer en mode tablette ?',
                message: 'Nous avons détecté que vous utilisez un appareil mobile. Souhaitez-vous passer sur l\'interface tablette ? Nous ne vous reposerons plus cette question sur cet appareil. Vous pourrez toujours passer d\'une interface à l\'autre depuis le menu de navigation.',
                buttons: [
                    {
                        title: 'Oui',
                        class: 'btn-primary',
                        handler: () => {
                            localStorage.setItem('tabletPopup', 'true')
                            this.$dialog.hide()
                            this.$router.push('/tablet')
                        }
                    },
                    {
                        title: 'Non',
                        handler: () => {
                            localStorage.setItem('tabletPopup', 'false')
                            this.$dialog.hide()
                        }
                    }
                ]
            }), 500)
        }
    }
})
</script>
