<template>
    <UiDropdown
        class="md:relative flex items-center quickcreate-menu-dropdown"
    >
        <template #trigger>
            <button
                type="button"
                class="text-gray-400 relative transition-colors hover:text-gray-500 group"
            >
                <span class="sr-only">Créer rapidement</span>
                <PlusIconOutline class="size-6" />
            </button>
        </template>

        <template #content>
            <div class="dropdown-menu top-6">
                <UiButtonModal id="quick-attendance-modal">
                    <template #trigger>
                        <button class="dropdown-menu-item">
                            <CalendarIconMicro class="icon" />
                            Encoder une présence
                        </button>
                    </template>
                    <quick-attendance-create-form />
                </UiButtonModal>
                <nuxt-link
                    to="/registrations/create"
                    class="dropdown-menu-item"
                >
                    <BookOpenIconMicro class="icon" />
                    Créer une inscription
                </nuxt-link>
                <UiButtonModal id="quick-event-create-modal">
                    <template #trigger>
                        <button class="dropdown-menu-item">
                            <ClockIconMicro class="icon" />
                            Créer un rappel
                        </button>
                    </template>
                    <reminder-create-form @created="$modal.hide('quick-event-create-modal')" />
                </UiButtonModal>
            </div>
        </template>
    </UiDropdown>
</template>

<script setup lang="ts">
</script>

<style>
.quickcreate-menu-dropdown > span {
    @apply flex items-center;
}
</style>
