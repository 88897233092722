<template>
    <div>
        <div class="mb-6">
            <RegistrationSelectInput
                v-model="registrationUuid"
                name="registration_id"
                label="Enfant"
                @select-registration="onSelectRegistration"
            />
        </div>
        <attendance-create-form
            v-if="registration"
            :baby="registration.baby"
            :registration="registration"
        />
    </div>
</template>

<script>
export default {

    data () {
        return {
            registrationUuid: undefined,
            registration: undefined,
            babies: []
        }
    },

    methods: {
        onSelectRegistration (registration) {
            this.registration = registration
        }
    }
}
</script>
